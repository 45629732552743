<template>
<div>

    <v-row align="center" class=" mb-3">
        <v-col cols="12" md="2" >
            <v-btn @click="openAddDialog()" color="primary" large class="btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
        <v-spacer />
        <!-- <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="searchName" label="بحث حسب الاسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col> -->
    </v-row>
 
    <v-card outlined>
        <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
            <template v-slot:item.content="{item}">
                <div v-html="item.content"/>
            </template>
            <template v-slot:item.phoneNumber="{item}">
                <div dir="ltr">
                    {{ item.phoneNumber }}
                </div>
            </template>
            <template v-slot:item.imageUrl="{item}">
                <v-avatar tile size="100">
                    <v-img :src="$url+'/'+item.imageUrl"/>
                </v-avatar>
            </template>
            <template v-slot:item.coverImageUrl="{item}">
                <v-avatar tile size="100">
                    <v-img :src="$url+'/'+item.coverImageUrl"/>
                </v-avatar>
            </template>
            <template v-slot:item.actions="{item}">

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id)" v-on="on" icon>
                            <v-icon color="error">
                                fi fi-rr-trash
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>

                <v-tooltip bottom="" >
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon>
                            <v-icon color="info">
                                fi fi-rr-edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>

            </template>
        </v-data-table>
    </v-card>
    <!-- <Pagination /> -->
    <Dialog />
</div>
</template>

<script>
export default {

    components: {
        Dialog: () => import("./dialog"),
    },

    data() {
        return {
            loading: false,
            searchName:"",
            headers: [
                {
                    text: "الايقونة",
                    value: "imageUrl"
                },
                {
                    text: "صورة الغلاف",
                    value: "coverImageUrl"
                },
                {
                    text: "العنوان",
                    value: "name"
                },
                {
                    text: "وصف مختصر",
                    value: "shortDescription"
                },
                {
                    text: "الوصف",
                    value: "content"
                },
                // {
                //     text: "رقم الهاتف",
                //     value: "phoneNumber",
                //     align: "left",
                //     width: "180"
                // },
                {
                    text: "الاجراءات",
                    value: "actions"
                }
            ],

            items: []
        }
    },

    created() {
        this.getItems()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);
    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`${this.$route.meta.endPoint}?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Name=${this.searchName}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },
    },

}
</script>
